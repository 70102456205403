/*
All of our layer information in one place.
*/

import * as constants from './constants.js';
import * as sources from './sources.js';

// step expression for opacity sets it to 0 until the zoom level at which
// the static tiles no longer appear.
// setting minzoom somewhat lower than this allows some preloading
// minzoom: the zoom level at which this should reach as much opacity as it will get
// opacity: the opacity we want this layer to display with
function _fadeInLayer(minzoom, opacity = 0.3) {
    return ['step', ['zoom'], 0, minzoom, opacity];
}

// data layers that are always on

// Dynamic base layer
const dynamicTilesLayer = {
    id: 'eroadmap_poly',
    type: 'fill',
    source: sources.dynamicTileSource.id,
    minzoom: constants.staticHexTilesMaxZoom - 1,
    'source-layer': sources.dynamicTileSource.id,
    paint: {
        // step expression for opacity sets it to 0 until the zoom level at which
        // the static tiles no longer appear.
        // setting minzoom somewhat lower than this allows some preloading
        'fill-opacity': [
            'step',
            ['zoom'],
            0,
            constants.staticHexTilesMaxZoom,
            constants.roadHexFillOpacity,
        ],
    },
};
const staticTilesLayer = {
    id: 'eroadmap_static_polygons',
    type: 'fill',
    source: sources.staticTileSource.id,
    maxzoom: constants.staticHexTilesMaxZoom,
    'source-layer': sources.dynamicTileSource.id, // yes, I know, but this is how it has to work
    paint: {
        'fill-opacity': constants.roadHexFillOpacity,
    },
};

const baseLayers = [dynamicTilesLayer, staticTilesLayer];

// our extra layers that are not visible by default
const evChargingLayer = {
    id: 'ev_charging',
    type: 'circle',
    source: sources.ev_charging.id,
    'source-layer': sources.ev_charging.id,
    layout: {
        visibility: 'none',
    },

    paint: {
        'circle-color': [
            'step',
            ['get', 'dc_fast_ports'],
            constants.softBlack,
            1,
            constants.fastPortColor,
        ],
        'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            1,
            ['/', ['get', 'fast_plus_level2_ports'], 30],
            constants.maxMapZoom,
            ['+', ['sqrt', ['get', 'fast_plus_level2_ports']], 5],
        ],
    },
};
const truckStopsLayer = {
    id: 'truck_stops',
    type: 'circle',
    source: sources.truck_stops.id,
    'source-layer': sources.truck_stops.id,
    layout: {
        visibility: 'none',
    },
    paint: {
        'circle-color': constants.softBlack,
        'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            1,
            ['/', ['get', 'prkngcount'], 30],
            constants.maxMapZoom,
            ['+', ['sqrt', ['get', 'prkngcount']], 5],
        ],
    },
};

// This is visible with a default opacity of 0 so that we can query its rendered
// features even when you can't see it.
const utilityServiceAreasLayer = {
    id: 'utility_service_areas',
    type: 'fill',
    source: sources.utility_service_areas.id,
    'source-layer': sources.utility_service_areas.id,
    layout: {
        visibility: 'visible',
    },
    paint: {
        'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'color_id'],
            1,
            constants.utilityServiceAreaColors[0],
            2,
            constants.utilityServiceAreaColors[1],
            3,
            constants.utilityServiceAreaColors[2],
            4,
            constants.utilityServiceAreaColors[3],
            5,
            constants.utilityServiceAreaColors[4],
            6,
            constants.utilityServiceAreaColors[5],
            7,
            constants.utilityServiceAreaColors[6],
        ],
        'fill-opacity': 0,
    },
};

const _hostingCapacityColorRamp = [
    'step',
    ['to-number', ['get', 'load_capacity']],
    constants.hostingCapacityColors[0],
    constants.hostingCapacityBreaks[0],
    constants.hostingCapacityColors[1],
    constants.hostingCapacityBreaks[1],
    constants.hostingCapacityColors[2],
    constants.hostingCapacityBreaks[2],
    constants.hostingCapacityColors[3],
    constants.hostingCapacityBreaks[3],
    constants.hostingCapacityColors[4],
];

const hostingCapacityDetailLayer = {
    id: 'hosting_capacity_detail',
    type: 'line',
    source: sources.hosting_capacity_detail.id,
    'source-layer': sources.hosting_capacity_detail.id,
    minzoom: constants.hostingCapacityAreaMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    paint: {
        'line-color': _hostingCapacityColorRamp,
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            1,
            1,
            constants.maxMapZoom,
            3,
        ],
        'line-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 1),
    },
};
const hostingCapacityPolygonsLayer = {
    id: 'hosting_capacity_polygons',
    type: 'fill',
    source: sources.hosting_capacity_polygons.id,
    'source-layer': sources.hosting_capacity_polygons.id,
    minzoom: constants.hostingCapacityAreaMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityComedTownshipLayer = {
    id: 'hosting_capacity_comed_township',
    type: 'fill',
    source: sources.hosting_capacity_comed_township.id,
    'source-layer': sources.hosting_capacity_comed_township.id,
    minzoom: constants.hostingCapacityAreaMaxZoom - 1,
    maxzoom: 10,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityComedGridSectionLayer = {
    id: 'hosting_capacity_comed_grid_section',
    type: 'fill',
    source: sources.hosting_capacity_comed_grid_section.id,
    'source-layer': sources.hosting_capacity_comed_grid_section.id,
    minzoom: 10,
    maxzoom: 12,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityComedGridQtrSectionLayer = {
    id: 'hosting_capacity_comed_grid_qtr_section',
    type: 'fill',
    source: sources.hosting_capacity_comed_grid_qtr_section.id,
    'source-layer': sources.hosting_capacity_comed_grid_qtr_section.id,
    minzoom: 12,
    maxzoom: 14,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityComedGridSixteenthSectionLayer = {
    id: 'hosting_capacity_comed_grid_16th_section',
    type: 'fill',
    source: sources.hosting_capacity_comed_grid_16th_section.id,
    'source-layer': sources.hosting_capacity_comed_grid_16th_section.id,
    minzoom: 14,
    maxzoom: 16,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityComedBufferedCircuitsLayer = {
    id: 'hosting_capacity_comed_circuits',
    type: 'fill',
    source: sources.hosting_capacity_comed_circuits.id,
    'source-layer': sources.hosting_capacity_comed_circuits.id,
    minzoom: 16,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityBgeMileLayer = {
    id: 'hosting_capacity_bge_mile',
    type: 'fill',
    source: sources.hosting_capacity_bge_mile.id,
    'source-layer': sources.hosting_capacity_bge_mile.id,
    maxzoom: 13,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityBgeQuarterMileLayer = {
    id: 'hosting_capacity_bge_quarter_mile',
    type: 'fill',
    source: sources.hosting_capacity_bge_quarter_mile.id,
    'source-layer': sources.hosting_capacity_bge_quarter_mile.id,
    minzoom: 13,
    maxzoom: 15,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityBgeSixteenthMileLayer = {
    id: 'hosting_capacity_bge_sixteenth_mile',
    type: 'fill',
    source: sources.hosting_capacity_bge_sixteenth_mile.id,
    'source-layer': sources.hosting_capacity_bge_sixteenth_mile.id,
    minzoom: 15,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': _hostingCapacityColorRamp,
        'fill-opacity': _fadeInLayer(constants.hostingCapacityAreaMaxZoom, 0.6),
    },
};
const hostingCapacityAreaLayer = {
    id: 'hosting_capacity_area',
    type: 'fill',
    source: sources.utility_service_areas.id,
    'source-layer': sources.utility_service_areas.id,
    maxzoom: constants.hostingCapacityAreaMaxZoom,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': constants.softBlack,
        'fill-outline-color': constants.softBlack,
        'fill-opacity': constants.defaultFillOpacity,
    },
    filter: ['in', 'Company_ID', ...constants.hostingCapacityUtilities],
};

const hostingCapacityDetailLayers = [
    hostingCapacityDetailLayer,
    hostingCapacityPolygonsLayer,
    hostingCapacityBgeMileLayer,
    hostingCapacityBgeQuarterMileLayer,
    hostingCapacityBgeSixteenthMileLayer,
    hostingCapacityComedTownshipLayer,
    hostingCapacityComedGridSectionLayer,
    hostingCapacityComedGridQtrSectionLayer,
    hostingCapacityComedGridSixteenthSectionLayer,
    hostingCapacityComedBufferedCircuitsLayer,
];
const justice40StaticLayer = {
    id: 'justice40_static',
    type: 'fill',
    source: sources.justice40_static.id,
    'source-layer': sources.justice40_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: ['get', 'justice40'],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
    },
};
const justice40DynamicLayer = {
    id: 'justice40_dynamic',
    type: 'fill',
    source: sources.justice40_dynamic.id,
    'source-layer': sources.justice40_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: ['get', 'justice40'],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
    },
};
const justice40Layers = [justice40StaticLayer, justice40DynamicLayer];

const pm25StaticLayer = {
    id: 'pm25_static',
    type: 'fill',
    source: sources.justice40_static.id,
    'source-layer': sources.justice40_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: ['>', ['get', 'pm25'], constants.pm25_MIN_VALUE],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
        'fill-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
    },
};
const pm25DynamicLayer = {
    id: 'pm25_dynamic',
    type: 'fill',
    source: sources.justice40_dynamic.id,
    'source-layer': sources.justice40_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: ['>', ['get', 'pm25'], constants.pm25_MIN_VALUE],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
        'fill-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
    },
};
const pm25Layers = [pm25StaticLayer, pm25DynamicLayer];
const transportationStaticLayer = {
    id: 'transportation_static',
    type: 'fill',
    source: sources.justice40_static.id,
    'source-layer': sources.justice40_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: ['>=', ['to-number', ['get', 'trnsac_percentile']], 90],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
    },
};
const transportationDynamicLayer = {
    id: 'transportation_dynamic',
    type: 'fill',
    source: sources.justice40_dynamic.id,
    'source-layer': sources.justice40_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: ['>=', ['to-number', ['get', 'trnsac_percentile']], 90],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
    },
};
const transportationLayers = [
    transportationStaticLayer,
    transportationDynamicLayer,
];

const extraLayers = [
    evChargingLayer,
    truckStopsLayer,
    utilityServiceAreasLayer,
    hostingCapacityAreaLayer,
    ...hostingCapacityDetailLayers,
    ...justice40Layers,
    ...pm25Layers,
    ...transportationLayers,
];

// For static tile layers that need to have slightly different styles in satellite vs road view
const staticPolygonLayers = [
    hostingCapacityAreaLayer,
    justice40StaticLayer,
    pm25StaticLayer,
    transportationStaticLayer,
];

export {
    baseLayers,
    dynamicTilesLayer,
    evChargingLayer,
    extraLayers,
    hostingCapacityDetailLayers,
    hostingCapacityAreaLayer,
    justice40Layers,
    pm25Layers,
    staticPolygonLayers,
    staticTilesLayer,
    transportationLayers,
    truckStopsLayer,
    utilityServiceAreasLayer,
};
